<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
	import { Heading, ShareModal, ShareButton } from '../atoms';

	let isActive: boolean = false;
</script>

<div class="ContactForm">
	<Container>
		<Row class="align-items-center">
			<Col lg={{ size: 6, offset: 3 }}>
				<div class="ContactForm-Content ml-lg-auto mr-lg-0 mx-md-auto">
					<Heading title="Your message has been sent" />
					<h5 class="mb-3">
						For more information on how Vapor Pin can improve procedures in your specific use case, complete
						the form to contact your regional distributor.
					</h5>
					<ShareButton label="Share with a Colleague" on:click={() => (isActive = !isActive)} />
					<ShareModal {isActive} onHide={() => (isActive = false)} />
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.ContactForm {
		width: 100%;
		display: block;
		padding: 5rem 0;
		background-color: #ffffff;

		&-Content {
			width: 100%;
			display: block;

			@media (min-width: 992px) {
				max-width: 35rem;
			}
		}
	}
</style>
