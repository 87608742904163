export const isValidEmail = (email) => {
    const regex = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/;
    return regex.test(String(email).toLowerCase());
};
export const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
    }).format(amount);
};
export const checkProperties = (obj) => {
    for (const key in obj) {
        if (obj[key] !== null && obj[key] != '')
            return false;
    }
    return true;
};
