<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
	import { Heading, Tabs, Accordion } from '../atoms';
</script>

<div class="TabListing">
	<Container>
		<Row>
			<Col>
				<Heading title="How it improves your process" />
				{#if window.innerWidth <= 768}
					<Accordion />
				{:else}
					<Tabs />
				{/if}
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.TabListing {
		width: 100%;
		display: block;
		padding: 3.125rem 0 0;
		background-image: url('/images/light-bollte.svg');
		background-position: -15rem top;
		background-repeat: no-repeat;
		background-size: contain;
	}
</style>
