<script lang="ts">
	import { Row, Col } from 'sveltestrap';

	let activeItem = null;

	const handleClick = (id: number) => {
		if (activeItem === id) {
			activeItem = null;
		} else {
			activeItem = id;
		}
	};
</script>

<div class="Accordion">
	<Row>
		<Col xs={12}>
			<ul class="Accordion-Nav">
				<li class={activeItem === 1 ? 'active' : ''} on:click={() => handleClick(1)}>
					<button>Vapour Intrusion</button>
					<div class="Accordion-Content">
						<div class="Accordion-Content__Item">
							<ul>
								<li>Less risk for leaks during sample collection</li>
								<li>Improvement in sample quality and diagnostic testing</li>
								<li>Eliminates the need for grout</li>
								<li>Easy installation, sampling, and retrieval for re-use</li>
								<li>Connects quickly and easily with sampling equipment</li>
								<li>Increases spatial resolution</li>
								<li>Reduces damage to the slab</li>
								<li>Less equipment & manpower required for installation</li>
								<li>Faster installation time than traditional methods</li>
							</ul>
						</div>
					</div>
				</li>
				<li class={activeItem === 2 ? 'active' : ''} on:click={() => handleClick(2)}>
					<button>Pressure Testing</button>
					<div class="Accordion-Content">
						<div class="Accordion-Content__Item">
							<p>
								A common approach to measuring sub-slab vacuum is to drill a hole in the slab, jam a
								manometer into the hole, and take a reading while running a radon fan or shop vacuum at
								the suction pit. Unfortunately, it can be difficult to form a tight seal between the
								manometer and the slab, and the test hole cannot be left open for subsequent testing.
							</p>
							<p>The Vapor Pin® sampling device:</p>
							<ul>
								<li>
									Provides a tight seal against the slab, preventing the loss of soil gas and
									improving reading accuracy by up to 25%
								</li>
								<li>Can be left in place indefinitely for future measurements</li>
								<li>
									Simplifies the retrieval process, allowing for faster and more efficient pressure
									testing procedures
								</li>
								<li>Is reusable, and can therefore be removed and reused elsewhere</li>
							</ul>
						</div>
					</div>
				</li>
				<li class={activeItem === 3 ? 'active' : ''} on:click={() => handleClick(3)}>
					<button>Soil Gas Sampling</button>
					<div class="Accordion-Content">
						<div class="Accordion-Content__Item">
							<p>
								The Vapor Pin® sampling device is ideally suited for locating Volatile Organic Compound
								(VOC) contamination sources beneath the pavement.
							</p>
							<p>Our approach to VOC source investigation consists of:</p>
							<ul>
								<li>Installing Vapor Pin® sampling devices along a grid</li>
								<li>Allowing the points to equilibrate</li>
								<li>Collecting readings with a multi-gas meter</li>
							</ul>
							<p>
								With a team of <span class="font-weight-bold">two people</span>, we’ve installed as many
								as
								<span class="font-weight-bold">90</span>
								Vapor Pin® sampling devices in <span class="font-weight-bold">one day</span> and sampled,
								removed the Vapor Pin® sampling devices, and plugged the holes on the following day.
							</p>
						</div>
					</div>
				</li>
			</ul>
		</Col>
	</Row>
</div>

<style lang="scss">
	.Accordion {
		width: 100%;
		display: block;

		&-Nav {
			height: 100%;
			margin: 0;
			padding: 0;

			li {
				cursor: pointer;
				list-style: none;
				display: block;
				padding: 1rem 0;

				button {
					width: 100%;
					display: block;
					border: 0;
					font-size: 1.125rem;
					font-weight: 700;
					text-align: left;
					color: rgba(0, 0, 0, 0.25);
					background-color: transparent;

					&:after {
						content: '';
						width: 1.5rem;
						height: 1.5rem;
						float: right;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 16l-6-6h12z'/%3E%3C/svg%3E");
					}
				}

				&.active {
					/* background-color: #f9f9f9; */

					button {
						color: var(--dark);

						&:after {
							transform: rotate(180deg);
						}
					}

					.Accordion-Content {
						display: block;
					}
				}
			}
		}

		&-Content {
			max-width: 38rem;
			display: none;
			border-radius: 0.313rem;
			padding: 1rem 0;
			/* background-color: #f9f9f9; */

			&__Item {
				animation-name: joeFadeIn;
				animation-duration: 1s;
				animation-fill-mode: both;

				p {
					&:last-child {
						margin-bottom: 0;
					}
				}

				ul {
					margin: 0;
					padding: 0;

					li {
						padding: 0;
						margin-left: 1rem;
						margin-bottom: 0.5rem;
					}

					& + p {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	@keyframes joeFadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
