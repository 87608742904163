<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
	import Carousel from '@beyonk/svelte-carousel';
	import { Heading } from '../atoms';
	import { packages } from '../../constants';
	import { formatPrice } from '../../utils';

	let activeSlide = 1;
	let prevItem = 0;
	let nextItem = 2;

	const handleChange = (event: any) => {
		const { currentSlide, slideCount } = event.detail;
		activeSlide = currentSlide;
		prevItem = currentSlide - 1;
		nextItem = currentSlide + 1;
	};
</script>

<div class="PackageListing">
	<Container>
		<Row>
			<Col xs={12}>
				<Heading title="Save time and costs" />
				<div class="PackageListing-Wrapper">
					<ul class="PackageListing-Group">
						<Carousel perPage={1} startIndex={1} on:change={handleChange}>
							{#each packages as { title, subTitle, price, data, imageUrl, moreUrl }, i}
								<li
									class={`PackageListing-Group__Item${i === activeSlide ? ' isActive' : ''}${
										i === prevItem ? ' prevItem' : ''
									}${i === nextItem ? ' nextItem' : ''}`}
								>
									<img class="bg" src={imageUrl} alt={title} />
									<!-- {#if i === 1}
										<span class="PackageListing-OfferTag">Best Offer</span>
									{/if} -->
									<div class="PackageListing-Group__Item--Content">
										<Row class="align-items-center">
											<Col xs={7}>
												{#if subTitle}
													<p class="text-secondary small mb-0">{subTitle}</p>
												{/if}
												<h5 class="PackageListing-Group__Item--Headline">{title}</h5>
											</Col>
											<Col xs={5} class="text-right">
												{#if price > 0}
													<p class="mb-0">from</p>
													<h2 class="PackageListing-Group__Item--Price">
														{formatPrice(price)}
													</h2>
												{/if}
											</Col>
										</Row>
										<ul class="PackageListing-Group__Item--Desc">
											{#each data as item}
												<li>{item}</li>
											{/each}
										</ul>
										<div class="w-100 text-center mt-3">
											<button as="a" href={moreUrl} class="PrimaryBtn">View Details</button>
										</div>
									</div>
								</li>
							{/each}
						</Carousel>
					</ul>
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.bg {
		object-fit: cover;
	}
	.PackageListing {
		width: 100%;
		display: block;
		padding: 3.125rem 0;

		&-Group {
			margin: 0 -1.875rem;
			padding: 0;
			display: flex;
			align-items: center;

			&__Item {
				list-style: none;
				width: 60%;
				min-height: 51rem;
				max-height: 51rem;
				margin: 1rem auto;
				position: relative;
				overflow: hidden;
				background-color: var(--white);
				@media (max-width: 500px) {
					width: 81%;
					min-width: 81%;
				}

				&:after {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: rgba(0, 0, 0, 0.15);
				}

				&.isActive {
					z-index: 999;
					box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);

					&:after {
						content: none;
					}
				}

				&.prevItem,
				&.nextItem {
					width: 120%;
					/* min-height: calc(50rem - 2rem); */
				}

				&.nextItem {
					margin-left: -20%;
				}

				&:nth-child(2) {
					& > div {
						padding-bottom: 8rem;
					}
				}

				img {
					width: 100%;
					height: 18.75rem;
				}

				&--Content {
					display: block;
					padding: 2rem;
				}

				&--Headline {
					font-weight: 700;
				}

				&--Price {
					font-weight: 700;
				}

				&--Desc {
					margin: 1rem 0 0 0;
					padding: 0;

					li {
						list-style: none;
						display: block;
						margin-bottom: 0.5rem;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&-OfferTag {
			position: absolute;
			top: 1rem;
			right: -2.5rem;
			width: 10rem;
			height: 3rem;
			display: block;
			font-family: 'Oswald', 'Open Sans', sans-serif;
			font-size: 1.063rem;
			font-weight: 700;
			line-height: 3rem;
			color: var(--white);
			text-align: center;
			text-transform: uppercase;
			background-color: var(--primary);
			transform: rotate(45deg);
		}

		&-Wrapper {
			@media (min-width: 768px) and (max-width: 1199.98px) {
				display: block;
				padding: 0rem;
				margin: 0 -3.4rem;
			}
		}
	}
</style>
