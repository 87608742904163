<script lang="ts">
	if (typeof window !== 'undefined') {
		window.addEventListener('click', () => hideChildItems());
	}

	const handleChild = (e) => {
		if (typeof window !== 'undefined') {
			if (!e.target.nextElementSibling.classList.contains('d-block')) {
				hideChildItems();
			}
			e.target.nextElementSibling.classList.add('d-block');
		}
	};

	const hideChildItems = () => {
		document.querySelectorAll('.NavList ul').forEach((el) => {
			if (el.classList.contains('d-block')) {
				el.classList.remove('d-block');
			}
		});
	};
</script>

<ul class="NavList">
	<li>
		<a href="https://www.vaporpin.eu/distributors/" class="PrimaryBtn">Distributors</a>
	</li>
	<li>
		<a href="https://www.vaporpin.eu/products/" class="PrimaryBtn" on:mouseover={handleChild}>Products</a>
		<ul>
			<li>
				<a href="https://www.vaporpin.eu/product-category/vapor-pin-kits/">Vapor Pin® Kits</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/product-category/individual-parts/">Individual Parts</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/product-category/secured-covers/">Secured Covers</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/product-category/tubing/">Tubing</a>
			</li>
		</ul>
	</li>
	<li>
		<a href="https://www.vaporpin.eu/blog/" class="PrimaryBtn" on:mouseover={handleChild}>News</a>
		<ul>
			<li>
				<a href="https://www.vaporpin.eu/videos/">Videos</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/vapor-pin-newsletter/">Newsletter and Events</a>
			</li>
		</ul>
	</li>
	<li>
		<a href="https://www.vaporpin.eu/resources/" class="PrimaryBtn" on:mouseover={handleChild}>Resources</a>
		<ul>
			<li>
				<a href="https://www.vaporpin.eu/resources/#WP">White Papers</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/resources/#SOP">SOPs</a>
			</li>
			<li>
				<a href="https://www.vaporpin.eu/faqs/">FAQs</a>
			</li>
		</ul>
	</li>
	<li>
		<a href="https://www.vaporpin.eu/contact/" class="PrimaryBtn">Contact Us</a>
	</li>
</ul>

<style lang="scss">
	.NavList {
		display: none;
	}

	@media (min-width: 768px) {
		.NavList {
			margin: 0;
			padding: 0;
			display: block;
			position: absolute;
			top: 20px;
			right: 45%;
			z-index: 99;

			li {
				margin: 0 5px;
				list-style: none;
				display: inline-block;
				position: relative;

				& > ul {
					display: none;
					min-width: 200px;
					margin: 0;
					padding: 10px 0;
					position: absolute;
					top: calc(100% + 5px);
					left: 0;
					border-radius: 10px;
					background-color: var(--primary);
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

					li {
						display: block;
						margin: 0;
						padding: 0;

						a {
							color: #fff;
							width: 100%;
							height: 48px;
							display: block;

							&:hover {
								background: #f57804;
							}
						}
					}
				}

				& > a {
					line-height: 48px;
					padding: 0 25px;
					margin: 0;

					&:hover {
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}
	}
</style>
