<script lang="ts">
	import { Modal } from './components/atoms';
	import {
		HeroBanner,
		TabListing,
		Testimony,
		VideoListing,
		Packages,
		PackagesSlider,
		ContactForm,
		Footer,
	} from './components/organisms';
	import SideNavbar from './components/organisms/SideNavbar.svelte';
	import TopNavbar from './components/organisms/TopNavbar.svelte';
	import Translate from './components/Translate.svelte';

	let isActive = false;
	let videoID = null;

	const handleOpen = (slug: string) => {
		isActive = !isActive;
		videoID = slug;
	};

	const handleClose = () => {
		isActive = false;
		videoID = null;
	};
</script>

<Translate />
<TopNavbar />
<SideNavbar />
<HeroBanner clickHandler={handleOpen} />
<TabListing />
<VideoListing clickHandler={handleOpen} />
<Testimony />
{#if window.innerWidth <= 768}
	<PackagesSlider />
{:else}
	<Packages />
{/if}
<ContactForm />
<Footer />
<Modal {isActive} {videoID} onHide={handleClose} />
