<script lang="ts">
	import { FormGroup, Label, Input, FormFeedback } from 'sveltestrap';

	export let label: string;
	export let name: string;
	export let isError: string = '';
</script>

<FormGroup>
	<Label for={name} class="sr-only">{label} <small class="text-danger">*</small></Label>
	<Input {name} invalid={!!isError} placeholder={label} on:input {...$$restProps}>
		<slot />
	</Input>
	<FormFeedback type="invalid">{isError}</FormFeedback>
</FormGroup>
