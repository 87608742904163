<script>
	import { Row, Col } from 'sveltestrap';
	let activeItem = 1;
</script>

<div class="Tabs">
	<Row>
		<Col md={4}>
			<ul class="Tabs-Nav">
				<li class={activeItem === 1 ? 'active' : ''} on:click={() => (activeItem = 1)}>Vapour Intrusion</li>
				<li class={activeItem === 2 ? 'active' : ''} on:click={() => (activeItem = 2)}>Pressure Testing</li>
				<li class={activeItem === 3 ? 'active' : ''} on:click={() => (activeItem = 3)}>Soil Gas Sampling</li>
			</ul>
		</Col>
		<Col md={8}>
			<div class="Tabs-Content">
				{#if activeItem === 1}
					<div class="Tabs-Content__Item">
						<ul>
							<li>Less risk for leaks during sample collection</li>
							<li>Improvement in sample quality and diagnostic testing</li>
							<li>Eliminates the need for grout</li>
							<li>Easy installation, sampling, and retrieval for re-use</li>
							<li>Connects quickly and easily with sampling equipment</li>
							<li>Increases spatial resolution</li>
							<li>Reduces damage to the slab</li>
							<li>Less equipment & manpower required for installation</li>
							<li>Faster installation time than traditional methods</li>
						</ul>
					</div>
				{:else if activeItem === 2}
					<div class="Tabs-Content__Item">
						<p>
							A common approach to measuring sub-slab vacuum is to drill a hole in the slab, jam a
							manometer into the hole, and take a reading while running a radon fan or shop vacuum at the
							suction pit. Unfortunately, it can be difficult to form a tight seal between the manometer
							and the slab, and the test hole cannot be left open for subsequent testing.
						</p>
						<p>The Vapor Pin® sampling device:</p>
						<ul>
							<li>
								Provides a tight seal against the slab, preventing the loss of soil gas and improving
								reading accuracy by up to 25%
							</li>
							<li>Can be left in place indefinitely for future measurements</li>
							<li>
								Simplifies the retrieval process, allowing for faster and more efficient pressure
								testing procedures
							</li>
							<li>Is reusable, and can therefore be removed and reused elsewhere</li>
						</ul>
					</div>
				{:else if activeItem === 3}
					<div class="Tabs-Content__Item">
						<p>
							The Vapor Pin® sampling device is ideally suited for locating Volatile Organic Compound
							(VOC) contamination sources beneath the pavement.
						</p>
						<p>Our approach to VOC source investigation consists of:</p>
						<ul>
							<li>Installing Vapor Pin® sampling devices along a grid</li>
							<li>Allowing the points to equilibrate</li>
							<li>Collecting readings with a multi-gas meter</li>
						</ul>
						<p>
							With a team of <span class="font-weight-bold">two people</span>, we’ve installed as many as
							<span class="font-weight-bold">90</span>
							Vapor Pin® sampling devices in <span class="font-weight-bold">one day</span> and sampled, removed
							the Vapor Pin® sampling devices, and plugged the holes on the following day.
						</p>
					</div>
				{/if}
			</div>
		</Col>
	</Row>
</div>

<style lang="scss">
	.Tabs {
		width: 100%;
		display: block;

		&-Nav {
			height: 100%;
			margin: 0;
			padding: 0 0 0 1rem;
			/* border-left: 0.313rem solid #f9f9f9; */

			li {
				cursor: pointer;
				list-style: none;
				display: block;
				font-family: 'Oswald', 'Open Sans', sans-serif;
				font-size: 1.5rem;
				font-weight: 700;
				padding: 1rem 0;
				color: rgba(0, 0, 0, 0.25);

				@media (min-width: 992px) {
					padding: 32px;
				}

				&.active {
					color: var(--dark);
					position: relative;

					&:before {
						content: '';
						width: 0.313rem;
						height: 100%;
						position: absolute;
						top: 0;
						left: -1.313rem;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
						background-color: #3583c2;
					}
				}
			}
		}

		&-Content {
			max-width: 38rem;
			display: block;
			font-size: 1.125rem;
			color: #666;
			border-radius: 0.313rem;
			padding: 1.5rem 2.5rem;

			&__Item {
				min-height: 450px;
				animation-name: joeFadeIn;
				animation-duration: 1s;
				animation-fill-mode: both;

				p {
					&:last-child {
						margin-bottom: 0;
					}
				}

				ul {
					margin: 0;
					padding: 0;

					li {
						margin-left: 1rem;
						margin-bottom: 0.5rem;
					}

					& + p {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	@keyframes joeFadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
