export const submitForm = async (formData) => {
    const res = await fetch('https://info.vaporpin.eu/wp-json/gf/v2/forms/1/submissions', {
        body: JSON.stringify(formData),
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
    });
    return (await res.json());
};
export const mapData = (data) => {
    const { company, country, email, jobTitle, message, name, phone, terms1, terms2 } = data;
    return {
        input_1: name,
        input_2: company,
        input_3: country,
        input_4: jobTitle,
        input_5: phone,
        input_6: email,
        input_7: message,
        input_8_1: terms1 ? '1' : '0',
        input_9_1: terms2 ? '1' : '0',
    };
};
