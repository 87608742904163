export { default as countryList } from './countryList';
export const testimonials = [
    {
        title: 'We can now take a sealed gas sample every time',
        body: 'It also means that we can literally install up to 100 pins in a day, compared to the normal systems where we can maybe only do 5 to 10 pins per day.',
        author: 'Gavin Stronach',
        company: 'Ribble Enviro, UK',
        imageUrl: '/images/testimonies/gavin-stronach.webp',
    },
    {
        title: 'We have been increasing our use of Vapor Pins',
        body: 'Due to a surge in demand from our clients, the easy installation/decommission with minimum disturbance is a huge advantage.',
        author: 'Lizzy Moorhouse',
        company: 'Delta-Simons Environmental Consultants',
        imageUrl: '/images/testimonies/lizzy-moorhouse.webp',
    },
];
export const videos = [
    {
        title: 'Installation (2m 33s)',
        imageUrl: '/images/video-thumbs/image-1.webp',
        videoID: 'xdD0L0fZUcc',
    },
    {
        title: 'Installation with Leak Test (15m 29s)',
        imageUrl: '/images/video-thumbs/image-2.webp',
        videoID: 'NQjUkxR9NjE',
    },
];
export const packages = [
    {
        title: 'VAPOR PIN® FLX-VPSS',
        subTitle: 'Sampling Device',
        price: 0,
        data: [
            'Featuring interchangeable connections for Swagelok, Entech Quick-Connect and the standard 1/4-inch barb connections, the FLX-VPSS is our most flexible pin. Its stainless steel composition offers improved durability and greater corrosion resistance, and is recommended for long-term installations and in corrosive environments.',
        ],
        imageUrl: '/images/package/pack-1.webp',
        moreUrl: 'https://www.vaporpin.eu/product/flx-vpss/',
    },
    {
        title: 'VAPOR PIN® Installation Kit',
        subTitle: 'Stainless Steel',
        price: 0,
        data: [
            '10 VAPOR PIN® sampling devices (Stainless Steel)',
            '20 VAPOR PIN® Sleeves',
            '20 VAPOR PIN® Caps',
            '10 Plastic Flush Mount Covers',
            '1 Installation/Extraction Tool',
            '1 Bottle Brush',
            '1 Water Dam for leak testing',
            '1 Vapor Pin SOP',
            'Hard-sided carrying case',
        ],
        imageUrl: '/images/package/pack-2.webp',
        moreUrl: 'https://www.vaporpin.eu/product/vapor-pin-kit/',
    },
    {
        title: 'Contractors VAPOR PIN®',
        subTitle: '4 Types Available',
        price: 0,
        data: [
            'VAPOR PIN® sampling device',
            'VAPOR PIN® Sleeves',
            'VAPOR PIN® Caps',
            'Stainless Steel Secure Covers',
            'Spanner Screwdriver',
            'Stainless Steel Drilling Guide',
            'Installation/Extraction Tool',
            'Bottle Brush',
            'Water Dam for leak testing',
            'Vapor Pin SOP',
            'Hard-sided carrying Case',
        ],
        imageUrl: '/images/package/pack-3.webp',
        moreUrl: 'https://www.vaporpin.eu/product/contractor-vapor-pin-kit/',
    },
];
