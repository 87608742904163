<script>
	import { Router, Link, Route } from 'svelte-routing';
	import App from './App.svelte';
	import MessageSend from './MessageSend.svelte';
	export let url = '';
</script>

<Router {url}>
	<Route path="message-sent" component={MessageSend} />
	<Route path="/"><App /></Route>
</Router>
