<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
</script>

<div class="Footer">
	<Container>
		<Row>
			<Col md={4} class="text-center">
				<p class="text-white mb-md-0">
					Copyright &copy; {new Date().getFullYear()} Cox-Colvin All rights reserved.
				</p>
			</Col>
			<Col md={4} class="text-center">
				<p class="text-white mb-md-0">European Patent Application Serial No. 16888475</p>
			</Col>
			<Col md={4} class="text-center">
				<div class="flex align-items-center mb-2">
					<a href="/" class="text-white">Privacy Policy</a>
					<a href="/" class="text-white ml-3">Cookies Policy</a>
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.Footer {
		width: 100%;
		display: block;
		padding: 1rem 0;
		font-size: 12px;
		background-color: #000000;
	}
</style>
