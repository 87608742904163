<script lang="ts">
	import { Row, Col } from 'sveltestrap';
	import FormInput from './FormInput.svelte';
	import FormCheckbox from './FormCheckbox.svelte';
	import { validateInput } from '../../form/validation';
	import { checkProperties } from '../../utils';
	import { mapData, submitForm } from '../../form/api';
	import { navigate } from 'svelte-routing';

	export let values = {
		name: '',
		country: 'USA',
		phone: '441200445804',
		company: '',
		jobTitle: '',
		email: '',
		message: '',
		terms1: false,
		terms2: false,
	};

	let errors = {
		name: null,
		// country: null,
		// phone: null,
		company: null,
		jobTitle: null,
		email: null,
		message: null,
		terms1: null,
		terms2: null,
	};

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		const newValue = { [name]: value };
		const data = validateInput(newValue);
		values = { ...values, ...newValue };
		errors = { ...errors, ...data };
	};

	const handleRadioChange = (event: any) => {
		const { id, checked } = event.target;
		const newValue = { [id]: checked };
		const data = validateInput(newValue);
		values = { ...values, ...newValue };
		errors = { ...errors, ...data };
	};

	const handleSubmit = async () => {
		const data = validateInput(values);
		errors = { ...errors, ...data };
		if (checkProperties(data)) {
			const { is_valid } = await submitForm(mapData(values));
			if (is_valid) {
				navigate('/message-sent');
			}
		}
	};
</script>

<div class="Form">
	<form on:submit|preventDefault={handleSubmit}>
		<Row>
			<Col md={6} class="pr-md-2">
				<FormInput
					label="Name *"
					name="name"
					bind:value={values.name}
					on:input={handleChange}
					isError={errors.name}
				/>
				<FormInput
					label="Email *"
					name="email"
					bind:value={values.email}
					on:input={handleChange}
					isError={errors.email}
				/>
				<!-- <FormInput
					label="Country *"
					name="country"
					type="select"
					bind:value={values.country}
					on:input={handleChange}
					isError={errors.country}
				>
					<option value="" disabled selected hidden>Country *</option>
					{#each countryList as item}
						<option value={item}>{item}</option>
					{/each}
				</FormInput>
				<FormInput
					label="Phone *"
					name="phone"
					bind:value={values.phone}
					on:input={handleChange}
					isError={errors.phone}
				/> -->
			</Col>
			<Col md={6} class="pl-md-2">
				<FormInput
					label="Company *"
					name="company"
					bind:value={values.company}
					on:input={handleChange}
					isError={errors.company}
				/>
				<FormInput
					label="Job Title *"
					name="jobTitle"
					bind:value={values.jobTitle}
					on:input={handleChange}
					isError={errors.jobTitle}
				/>
			</Col>
			<Col xs={12}>
				<FormInput
					rows={5}
					label="Please provide details of your use cases and any questions that you may have. *"
					name="message"
					type="textarea"
					bind:value={values.message}
					on:input={handleChange}
					isError={errors.message}
				/>
				<FormCheckbox
					id="terms1"
					bind:checked={values.terms1}
					on:input={handleRadioChange}
					isError={errors.terms1}
					label="I agree to have the data provided stored and used in accordance with to the privacy policy."
				/>
				<FormCheckbox
					id="terms2"
					bind:checked={values.terms2}
					on:input={handleRadioChange}
					isError={errors.terms2}
					label="I agree to the data I have been provided being passed to the company acting as the distributor of
                Vapor Pin products in my country."
				/>
			</Col>
		</Row>
		<button class="PrimaryBtn">Make Enquiry</button>
	</form>
</div>

<style lang="scss">
	.Form {
		display: block;
		padding: 3rem 2rem;
		border-radius: 0.313rem;
		background-color: var(--white);

		@media (max-width: 767.98px) {
			padding: 3rem 1rem;
		}
	}
</style>
