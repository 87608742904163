<script lang="ts">
	export let isActive: boolean = false;
	export let videoID: string;
	export let onHide: () => void;

	const updateScroll = (data: boolean) => {
		if (data) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}
	};

	$: updateScroll(isActive);
</script>

{#if isActive && videoID}
	<div class="Modal" on:click={onHide}>
		<div class="Modal-Dialog">
			<div class="Modal-Dialog__Content">
				<button class="Modal-Dialog__Content--CloseBtn" on:click={onHide} />
				<div class="Modal-Dialog__VideoBox">
					<iframe
						width="100%"
						height="100%"
						src={`https://www.youtube.com/embed/${videoID}?autoplay=1&controls=0`}
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
				</div>
			</div>
		</div>
	</div>
{/if}

<style lang="scss">
	.Modal {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1060;
		background-color: rgba(0, 0, 0, 0.75);

		&-Dialog {
			position: relative;
			width: 100%;
			max-width: 35rem;
			height: calc(100% - 3.5rem);
			margin: 1.75rem auto;
			display: flex;
			align-items: center;
			justify-content: center;

			&__Content {
				position: relative;
				width: 100%;
				padding-top: 56.25%; /* 16:9 Aspect Ratio */
				margin: 0 1.875rem;
				border-radius: 0.313rem;
				background-color: #000000;
				box-shadow: 0 0.313rem 2rem rgba(0, 0, 0, 0.32);

				&--CloseBtn {
					position: absolute;
					top: -1.5rem;
					right: 0;
					box-sizing: content-box;
					width: 1em;
					height: 1em;
					padding: 0.25em 0.25em;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
					background-color: transparent;
					border: 0;
				}
			}

			&__VideoBox {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				overflow: hidden;
				border-radius: 0.313rem;
			}
		}
	}
</style>
