<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
	import { Heading } from '../atoms';
	import { packages } from '../../constants';
	import { formatPrice } from '../../utils';
</script>

<div class="PackageListing">
	<Container>
		<Row>
			<Col xs={12}>
				<Heading title="Save time and costs" />
				<div class="PackageListing-Wrapper">
					<ul class="PackageListing-Group">
						{#each packages as { title, subTitle, price, data, imageUrl, moreUrl }, i}
							<li class="PackageListing-Group__Item">
								<img src={imageUrl} alt={title} />
								<!-- {#if i === 1}
									<span class="PackageListing-OfferTag">Best Offer</span>
								{/if} -->
								<div class="PackageListing-Group__Item--Content">
									<Row class="align-items-center">
										<Col xs={6} class="pr-1">
											{#if subTitle}
												<p class="text-secondary small mb-0">{subTitle}</p>
											{/if}
											<h5 class="PackageListing-Group__Item--Headline">{title}</h5>
										</Col>
										<Col xs={6} class="text-right pl-1">
											{#if price > 0}
												<p class="mb-0">from</p>
												<h2 class="PackageListing-Group__Item--Price">
													{formatPrice(price)}
												</h2>
											{/if}
										</Col>
									</Row>
									<ul class="PackageListing-Group__Item--Desc">
										{#each data as item}
											<li>{item}</li>
										{/each}
									</ul>
									<div class="w-100 text-center mt-3">
										<button as="a" href={moreUrl} class="PrimaryBtn">View Details</button>
									</div>
								</div>
							</li>
						{/each}
					</ul>
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.PackageListing {
		width: 100%;
		display: block;
		padding: 3.125rem 0;

		&-Group {
			margin: 0;
			padding: 0;
			display: flex;
			align-items: baseline;

			&__Item {
				list-style: none;
				width: calc(33.33% - 1rem);
				margin: 0 1rem;
				position: relative;
				overflow: hidden;
				box-shadow: 0 0.313rem 2rem rgba(0, 0, 0, 0.32);

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				img {
					width: 100%;
					height: 18.75rem;
				}

				&--Content {
					display: block;
					padding: 2.188rem 1.5rem;
				}

				&--Headline {
					font-weight: 700;
				}

				&--Price {
					font-size: 36px;
					font-weight: 700;
				}

				&--Desc {
					margin: 1rem 0 0 0;
					padding: 0;

					li {
						list-style: none;
						display: block;
						margin-bottom: 0.5rem;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			@media (max-width: 767.98px) {
				flex-wrap: wrap;

				&__Item {
					width: 100%;
					flex: 0 0 100%;
					margin: 0 0 2rem 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		/* &-OfferTag {
			position: absolute;
			top: 1rem;
			right: -2.5rem;
			width: 10rem;
			height: 3rem;
			display: block;
			font-family: 'Oswald', 'Open Sans', sans-serif;
			font-size: 1.063rem;
			font-weight: 700;
			line-height: 3rem;
			color: var(--white);
			text-align: center;
			text-transform: uppercase;
			background-color: var(--primary);
			transform: rotate(45deg);
		} */
	}
</style>
