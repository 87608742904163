<script lang="ts">
	import { Row, Col, Container } from 'sveltestrap';

	export let clickHandler: (slug: string) => void;
</script>

<div class="HeroBanner">
	<Container>
		<Row class="align-items-center">
			<Col lg={6}>
				<img class="HeroBanner-Brand mb-5 mb-lg-0" src="/images/logo.webp" alt="Vapo Pin" />
			</Col>
			<Col lg={6}>
				<div class="HeroBanner-Video text-md-center" on:click={() => clickHandler('SGpisqe07kI')}>
					<img src="/images/video-banner.webp" alt="" />
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.HeroBanner {
		display: block;
		width: 100%;
		padding: 5rem 0 11.25rem 0;
		position: relative;
		background-image: url('/images/hero-banner.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 6.25rem;
			position: absolute;
			left: 0;
			bottom: -1px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDMuNEMxMDUwLjU5IDE4IDEwMTkuNCA4NC44OSA3MzQuNDIgODQuODljLTMyMCAwLTMyMC04NC4zLTY0MC04NC4zQzU5LjQuNTkgMjguMiAxLjYgMCAzLjRWMTQwaDEyODB6IiBmaWxsLW9wYWNpdHk9Ii4zIi8+PHBhdGggZD0iTTAgMjQuMzFjNDMuNDYtNS42OSA5NC41Ni05LjI1IDE1OC40Mi05LjI1IDMyMCAwIDMyMCA4OS4yNCA2NDAgODkuMjQgMjU2LjEzIDAgMzA3LjI4LTU3LjE2IDQ4MS41OC04MFYxNDBIMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCA1MS43NmMtMjAxIDEyLjQ5LTI0Mi40MyA1My40LTUxMy41OCA1My40LTMyMCAwLTMyMC01Ny02NDAtNTctNDguODUuMDEtOTAuMjEgMS4zNS0xMjYuNDIgMy42VjE0MGgxMjgweiIvPjwvZz48L3N2Zz4=');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		&-Brand {
			width: 100%;
			max-width: 29.063rem;
			margin: 0 auto;
			display: block;
		}

		&-Video {
			cursor: pointer;

			img {
				width: 100%;
				max-width: 29.063rem;
				height: auto;
				margin: 0 auto;
			}
		}
	}
</style>
