<script lang="ts">
	import { Container, Row, Col } from 'sveltestrap';
	import { Heading, Form, ShareModal, ShareButton } from '../atoms';

	let isActive: boolean = false;
</script>

<div class="ContactForm">
	<Container>
		<Row class="align-items-center">
			<Col lg={6} class="pr-lg-0">
				<div class="ContactForm-Content ml-lg-auto mr-lg-0 mx-md-auto">
					<Heading classes="text-white" title="Questions or Quote" />
					<h5 class="text-white mb-3">
						For more information on how Vapor Pin can improve procedures in your specific use case, complete
						the form to contact your regional distributor.
					</h5>
					<ShareButton label="Share with a Colleague" on:click={() => (isActive = !isActive)} />
					<ShareModal {isActive} onHide={() => (isActive = false)} />
				</div>
			</Col>
			<Col lg={6} class="pl-lg-0">
				<div class="ContactForm-Form">
					<Form />
				</div>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.ContactForm {
		width: 100%;
		display: block;
		padding: 9.375rem 0;
		background-color: #eaf2f9;
		background-image: url('/images/contact.webp');
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		&:before,
		&:after {
			display: block;
			width: 100%;
			height: 6.25rem;
			position: absolute;
			left: 0;
			content: '';
			background-repeat: no-repeat;
			background-size: cover;
		}

		&:before {
			top: -1px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
			background-position: top center;
		}

		&:after {
			bottom: -1px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwMDAwMDAiPjxwYXRoIGQ9Ik0zMjAgMjhjMzIwIDAgMzIwIDg0IDY0MCA4NCAxNjAgMCAyNDAtMjEgMzIwLTQydjcwSDBWNzBjODAtMjEgMTYwLTQyIDMyMC00MnoiLz48L2c+PC9zdmc+');
			background-position: bottom center;
		}

		&-Content {
			width: 100%;
			display: block;
			padding: 5rem 2rem;
			background-color: #2c3c4f;

			/* @media (min-width: 992px) {
				max-width: 35rem;
			} */
		}

		&-Form {
			max-width: 484px;

			@media (max-width: 991.98px) {
				width: 100%;
				max-width: 100%;
				display: block;
				padding: 0 2rem 5rem;
				background-color: #2c3c4f;
			}
		}

		@media (max-width: 991.98px) {
			background-position: 50%;
		}

		@media (max-width: 767.98px) {
			background-position: 60%;

			&-Content,
			&-Form {
				margin-bottom: 0;
				background-color: rgba(44, 60, 79, 0.85);
			}
		}

		@media (max-width: 577.98px) {
			&-Content,
			&-Form {
				width: auto;
				max-width: inherit;
				margin-left: -12px;
				margin-right: -12px;
			}
		}
	}
</style>
