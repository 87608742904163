<script lang="ts">
	let isActive = false;

	const updatebody = (active: boolean) => {
		if (active) {
			document.querySelector('body').classList.add('modal-open');
		} else {
			document.querySelector('body').classList.remove('modal-open');
		}
	};
	$: updatebody(isActive);
</script>

<button class="PrimaryBtn navButton" on:click={() => (isActive = !isActive)}>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
		><path fill="none" d="M0 0h24v24H0z" /><path
			d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
			fill="rgba(255,255,255,1)"
		/></svg
	>
</button>

<div class="sidebarNav" style="left: {isActive ? 0 : '-300px'}">
	<ul class="NavList">
		<li>
			<a href="https://www.vaporpin.eu/distributors/">Distributors</a>
		</li>
		<li>
			<a href="https://www.vaporpin.eu/products/">Products</a>
			<ul>
				<li>
					<a href="https://www.vaporpin.eu/product-category/vapor-pin-kits/">Vapor Pin® Kits</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/product-category/individual-parts/">Individual Parts</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/product-category/secured-covers/">Secured Covers</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/product-category/tubing/">Tubing</a>
				</li>
			</ul>
		</li>
		<li>
			<a href="https://www.vaporpin.eu/blog/">News</a>
			<ul>
				<li>
					<a href="https://www.vaporpin.eu/videos/">Videos</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/vapor-pin-newsletter/">Newsletter and Events</a>
				</li>
			</ul>
		</li>
		<li>
			<a href="https://www.vaporpin.eu/resources/">Resources</a>
			<ul>
				<li>
					<a href="https://www.vaporpin.eu/resources/#WP">White Papers</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/resources/#SOP">SOPs</a>
				</li>
				<li>
					<a href="https://www.vaporpin.eu/faqs/">FAQs</a>
				</li>
			</ul>
		</li>
		<li>
			<a href="https://www.vaporpin.eu/contact/">Contact Us</a>
		</li>
	</ul>
</div>
{#if isActive}
	<div class="Backdrop" on:click={() => (isActive = false)} />
{/if}

<style lang="scss">
	.navButton {
		padding: 0 10px;
		border-radius: 5px;
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: 99;

		@media (min-width: 768px) {
			display: none;
		}
	}

	.sidebarNav {
		position: absolute;
		width: 300px;
		height: 100%;
		padding: 20px 15px;
		background-color: #fff;
		z-index: 1000;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
		transition: all 0.2s ease-in-out;

		.NavList {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				display: block;

				a {
					width: 100%;
					padding: 5px;
					font-size: 18px;
					font-weight: 600;
					display: block;
				}

				& > ul {
					padding-left: 20px;

					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.Backdrop {
		width: 100vw;
		height: 100vh;
		display: block;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: 99;
	}
</style>
