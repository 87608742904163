<script lang="ts">
	export let isActive: boolean = false;
	export let onHide: () => void;
</script>

{#if isActive}
	<div class="Modal" on:click={onHide}>
		<div class="Modal-Dialog">
			<div class="Modal-Dialog__Content">
				<button class="Modal-Dialog__Content--CloseBtn" on:click={onHide} />
				<div class="Modal-Dialog__Box">
					<div class="w-100 h-100 d-flex align-items-center justify-content-center">
						<div class="text-center pl-3 pr-3">
							<h4 class="font-weight-semibold mb-3 mb-lg-4" style="max-width: 445px">
								Share & discuss how Vapor Pin can improve your testing procedures
							</h4>
							<ul>
								<li>
									<a
										href="mailto:?subject=Vapor%20Pin%20%C2%AE%20%7C%20Sub-Slab%20Gas%20Sampling%20%26%20Vapor%20Intrusion%20Solution&amp;body=https%3A%2F%2Finfo.vaporpin.eu%2F"
										target="_blank"
										rel="noopener"
									>
										<img src="/images/social/email.webp" alt="Email" />
									</a>
								</li>
								<li>
									<a
										href="https://api.whatsapp.com/send?text=Vapor%20Pin%20%C2%AE%20%7C%20Sub-Slab%20Gas%20Sampling%20%26%20Vapor%20Intrusion%20Solution https%3A%2F%2Finfo.vaporpin.eu%2F"
										target="_blank"
										rel="noopener"
									>
										<img src="/images/social/whatsapp.webp" alt="WhatsApp" />
									</a>
								</li>
								<li>
									<a
										href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Finfo.vaporpin.eu%2F&amp;title=Vapor%20Pin%20%C2%AE%20%7C%20Sub-Slab%20Gas%20Sampling%20%26%20Vapor%20Intrusion%20Solution"
										target="_blank"
										rel="noopener"
									>
										<img src="/images/social/linkedin.webp" alt="LinkedIn" />
									</a>
								</li>
								<li>
									<a
										href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Finfo.vaporpin.eu%2F&amp;t=https%3A%2F%2Finfo.vaporpin.eu%2F"
										target="_blank"
										rel="noopener"
									>
										<img src="/images/social/facebook.webp" alt="Facebook" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}

<style lang="scss">
	.Modal {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1060;
		background-color: rgba(0, 0, 0, 0.75);

		&-Dialog {
			position: relative;
			width: 100%;
			max-width: 35rem;
			height: calc(100% - 3.5rem);
			margin: 1.75rem auto;
			display: flex;
			align-items: center;
			justify-content: center;

			&__Content {
				position: relative;
				width: 100%;
				padding-top: 56.25%;
				border-radius: 0.313rem;
				background-color: var(--white);
				box-shadow: 0 0.313rem 2rem rgba(0, 0, 0, 0.32);
				background-image: url('/images/share-bg.webp');
				background-position: bottom center;
				background-size: contain;
				background-repeat: no-repeat;

				&--CloseBtn {
					position: absolute;
					top: -1.5rem;
					right: 0;
					box-sizing: content-box;
					width: 1em;
					height: 1em;
					padding: 0.25em 0.25em;
					background: transparent
						url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
						center/1em auto no-repeat;
					border: 0;
				}
			}

			&__Box {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				overflow: hidden;
				border-radius: 0.313rem;

				ul {
					margin: 0;
					padding: 0;

					li {
						margin: 0 0.313rem;
						list-style: none;
						display: inline-block;

						img {
							width: 2.5rem;
							height: 2.5rem;
						}
					}
				}
			}
		}
	}
</style>
