<script lang="ts">
	export let title: string;
	export let classes: string = '';
</script>

<h1 class={`SectionHeader${` ${classes}`}`}>{title}</h1>

<style lang="scss">
	.SectionHeader {
		display: block;
		font-family: 'Oswald', 'Open Sans', sans-serif;
		font-size: 2.688rem;
		font-weight: 700;
		color: var(--dark);
		margin-bottom: 3rem;
		padding-left: 0.5rem;
		border-left: 0.625rem solid var(--primary);

		@media (max-width: 767.98px) {
			font-size: 1.8rem;
		}
	}
</style>
