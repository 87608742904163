import { isValidEmail } from '../utils';
export const validateInput = (data) => {
    let errors = {};
    if (data && Object.keys(data).length > 0) {
        Object.entries(data).forEach(([key, value]) => {
            switch (key) {
                case 'name':
                    if (!value) {
                        errors.name = 'Name is required';
                    }
                    else {
                        errors.name = null;
                    }
                    break;
                // case 'country':
                // 	if (!value) {
                // 		errors.country = 'Country is required';
                // 	} else {
                // 		errors.country = null;
                // 	}
                // 	break;
                // case 'phone':
                // 	if (!value) {
                // 		errors.phone = 'Phone is required';
                // 	} else {
                // 		errors.phone = null;
                // 	}
                // 	break;
                case 'company':
                    if (!value) {
                        errors.company = 'Company is required';
                    }
                    else {
                        errors.company = null;
                    }
                    break;
                case 'jobTitle':
                    if (!value) {
                        errors.jobTitle = 'Job title is required';
                    }
                    else {
                        errors.jobTitle = null;
                    }
                    break;
                case 'email':
                    if (!value) {
                        errors.email = 'Email address is required';
                    }
                    else if (value && !isValidEmail(value)) {
                        errors.email = 'Email address is invalid';
                    }
                    else {
                        errors.email = null;
                    }
                    break;
                case 'message':
                    if (!value) {
                        errors.message = 'Message is required';
                    }
                    else {
                        errors.message = null;
                    }
                    break;
                case 'terms1':
                    if (!value) {
                        errors.terms1 = 'Accept this box if you want to process';
                    }
                    else {
                        errors.terms1 = null;
                    }
                    break;
                case 'terms2':
                    if (!value) {
                        errors.terms2 = 'Accept this box if you want to process';
                    }
                    else {
                        errors.terms2 = null;
                    }
                    break;
            }
        });
    }
    return errors;
};
