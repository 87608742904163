<script>
	import Carousel from '@beyonk/svelte-carousel';
	import { Col, Row } from 'sveltestrap';
	import { testimonials } from '../../constants';
</script>

<Carousel perPage={1}>
	{#each testimonials as { title, body, author, company, imageUrl }}
		<div class="Slider-Item">
			<Row>
				<Col lg={7} class="order-12">
					<div class="Slider-Item__Comments mx-auto">
						<h3 class="Slider-Item__Comments--Headline">{title}</h3>
						<p class="Slider-Item__Comments--Testimonial">{body}</p>
						<h6 class="Slider-Item__Comments--Author">{author}</h6>
						<p class="Slider-Item__Comments--Company">{company}</p>
					</div>
				</Col>
				<Col lg={5} class="order-lg-12 text-center text-lg-left">
					<img class="Slider-Item__AuthorImage mb-4 md-lg-0" src={imageUrl} alt={author} />
				</Col>
			</Row>
		</div>
	{/each}
</Carousel>

<style lang="scss">
	.Slider {
		&-Item {
			width: 100%;

			&__Comments {
				min-height: 100px;
				padding-left: 5rem;
				position: relative;

				@media (min-width: 992px) {
					margin-left: 68px !important;
				}

				@media (max-width: 991.98px) {
					max-width: 28rem;
					padding-left: 4rem;
				}

				&:before {
					content: '';
					width: 3.125rem;
					height: 3.125rem;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					background-image: url('/images/quotes.svg');
					background-position: top left;
					background-repeat: no-repeat;
					background-size: contain;
				}

				&--Headline {
					font-family: 'Oswald', 'Open Sans', sans-serif;
					font-size: 1.563rem;
					font-weight: bold;
					margin-bottom: 1.5rem;
				}

				@media (max-width: 575.98px) {
					padding-left: 0;

					&--Headline {
						margin-left: 2rem;
					}
				}

				&--Testimonial {
					max-width: 28rem;
					font-family: 'Oswald', 'Open Sans', sans-serif;
					font-size: 18px;
					line-height: 1.8em;
					font-style: italic;
					font-weight: 300;
					color: #666;
					margin-bottom: 2rem;
				}

				&--Author {
					font-family: 'Oswald', 'Open Sans', sans-serif;
					font-size: 1rem;
					font-weight: 600;
					margin-bottom: 5px;
				}
			}

			&__AuthorImage {
				width: 18.75rem;
				height: auto;
				border-radius: 0.313rem;

				@media (min-width: 768px) and (max-width: 991.98px) {
					margin-left: 110px;
				}
			}
		}
	}
</style>
