<script lang="ts">
	export let label: string;
</script>

<button class="ShareButton" on:click {...$$restProps}>{label}</button>

<style lang="scss">
	.ShareButton {
		padding: 0;
		margin: 0;
		font-size: 1.125rem;
		font-weight: 600;
		color: var(--white);
		border: 0;
		background-color: transparent;
		display: flex;
		align-items: center;

		&:before {
			content: '';
			width: 43px;
			height: 40px;
			margin-right: 10px;
			display: inline-block;
			background-image: url('/images/share.svg');
			background-repeat: no-repeat;
			background-size: cover;
		}

		&:hover {
			color: var(--primary);

			&:before {
				background-image: url('/images/share-color.svg');
			}
		}
	}
</style>
