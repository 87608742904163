<script lang="ts">
	import { Modal } from './components/atoms';
	import { HeroBanner, Confirmation, Footer } from './components/organisms';
	import Translate from './components/Translate.svelte';

	let isActive = false;
	let videoID = null;

	const handleOpen = (slug: string) => {
		isActive = !isActive;
		videoID = slug;
	};

	const handleClose = () => {
		isActive = false;
		videoID = null;
	};
</script>

<Translate />
<HeroBanner clickHandler={handleOpen} />
<Confirmation />
<Footer />
<Modal {isActive} {videoID} onHide={handleClose} />
