<script>
	window.onTranslateLoaded = () => {
		console.log('test');
		new google.translate.TranslateElement({ pageLanguage: 'en', autoDisplay: false }, 'google_translate_element2');
	};
	function GTranslateFireEvent(a, b) {
		try {
			if (document.createEvent) {
				var c = document.createEvent('HTMLEvents');
				c.initEvent(b, true, true);
				a.dispatchEvent(c);
			} else {
				var c = document.createEventObject();
				a.fireEvent('on' + b, c);
			}
		} catch (e) {}
	}

	function doGTranslate(a) {
		if (a.value) a = a.value;
		if (a == '') return;
		var b = a.split('|')[1];
		var c;
		var d = document.getElementsByTagName('select');
		for (var i = 0; i < d.length; i++) if (d[i].className == 'goog-te-combo') c = d[i];
		if (
			document.getElementById('google_translate_element2') == null ||
			document.getElementById('google_translate_element2').innerHTML.length == 0 ||
			c.length == 0 ||
			c.innerHTML.length == 0
		) {
			setTimeout(function () {
				doGTranslate(a);
			}, 500);
		} else {
			c.value = b;
			GTranslateFireEvent(c, 'change');
			GTranslateFireEvent(c, 'change');
		}
	}

	const handleClick = (e) => {
		e.target.nextElementSibling.classList.toggle('d-block');
	};
</script>

<svelte:head>
	<script src="https://translate.google.com/translate_a/element.js?cb=onTranslateLoaded"></script>
</svelte:head>

<button class="PrimaryBtn LanguageButton" on:click={handleClick}>Language</button>
<div class="LangButtons">
	<button
		on:click={() => doGTranslate('en|ar')}
		title="Arabic"
		class="gflag nturl"
		style="background-position:-100px -0px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Arabic" />
	</button>

	<button
		on:click={() => doGTranslate('en|en')}
		title="English"
		class="gflag nturl"
		style="background-position:-0px -0px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="English" />
	</button>
	<button
		on:click={() => doGTranslate('en|fr')}
		title="French"
		class="gflag nturl"
		style="background-position:-200px -100px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="French" />
	</button>
	<button
		on:click={() => doGTranslate('en|de')}
		title="German"
		class="gflag nturl"
		style="background-position:-300px -100px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="German" />
	</button>
	<button
		onclick="doGTranslate('en|da');return false;"
		title="Danish"
		class="gflag nturl"
		style="background-position:-700px -0px;"
		><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Danish" /></button
	><button
		onclick="doGTranslate('en|pl');return false;"
		title="Polish"
		class="gflag nturl"
		style="background-position:-200px -200px;"
		><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Polish" /></button
	><button
		onclick="doGTranslate('en|pt');return false;"
		title="Portuguese"
		class="gflag nturl"
		style="background-position:-300px -200px;"
		><img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Portuguese" /></button
	>
	<button
		on:click={() => doGTranslate('en|it')}
		title="Italian"
		class="gflag nturl"
		style="background-position:-600px -100px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Italian" />
	</button>
	<button
		on:click={() => doGTranslate('en|no')}
		title="Norwegian"
		class="gflag nturl"
		style="background-position:-100px -200px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Norwegian" />
	</button>
	<button
		on:click={() => doGTranslate('en|ru')}
		title="Russian"
		class="gflag nturl"
		style="background-position:-500px -200px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Russian" />
	</button>
	<button
		on:click={() => doGTranslate('en|es')}
		title="Spanish"
		class="gflag nturl"
		style="background-position:-600px -200px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Spanish" />
	</button>
	<button
		on:click={() => doGTranslate('en|sv')}
		title="Swedish"
		class="gflag nturl"
		style="background-position:-700px -200px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Swedish" />
	</button>
	<button
		on:click={() => doGTranslate('en|uk')}
		title="Ukrainian"
		class="gflag nturl"
		style="background-position:-100px -400px;"
	>
		<img src="//gtranslate.net/flags/blank.png" height="24" width="24" alt="Ukrainian" />
	</button>
</div>

<div id="google_translate_element2" />

<style lang="scss">
	.LanguageButton {
		position: absolute;
		top: 20px;
		right: 8%;
		z-index: 99;
	}

	.LangButtons {
		position: absolute;
		top: 70px;
		right: 10%;
		z-index: 99;
		display: none;
		padding: 10px;
		text-align: center;
		border-radius: 10px;
		background-color: #fff;

		button {
			padding: 0;
			margin: 0;
			border: 0;
			display: block;
			background-color: transparent;

			@media (min-width: 768px) {
				margin-right: 5px;
			}
		}
	}

	button.gflag {
		vertical-align: middle;
		font-size: 16px;
		padding: 1px 0;
		background-repeat: no-repeat;
		background-image: url(//gtranslate.net/flags/24.png);
	}
	button.gflag img {
		border: 0;
	}
	button.gflag:hover {
		background-image: url(//gtranslate.net/flags/24.png);
	}
	:global(#goog-gt-tt) {
		display: none !important;
	}
	:global(.goog-te-banner-frame) {
		display: none !important;
	}
	:global(.goog-te-menu-value:hover) {
		text-decoration: none !important;
	}
	:global(body) {
		top: 0 !important;
	}
	#google_translate_element2 {
		display: none !important;
	}
</style>
