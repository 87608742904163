<script lang="ts">
	import { Container, Row, Col, Button } from 'sveltestrap';
	import { Heading } from '../atoms';
	import { videos } from '../../constants';

	export let clickHandler: (videoID: string) => void;
</script>

<div class="VideoListing">
	<Container>
		<Row>
			<Col xs={12}>
				<Heading title="How does it work?" />
			</Col>
			{#each videos as { title, imageUrl, videoID }}
				<Col md={6}>
					<h5 class="VideoListing-Headline">{title}</h5>
					<div class="VideoListing-Image mb-5 md-md-0" on:click={() => clickHandler(videoID)}>
						<img src={imageUrl} alt={title} />
					</div>
				</Col>
			{/each}
			<Col xs={12} class="text-center">
				<button as="a" class="PrimaryBtn" href="https://www.vaporpin.eu/resources/#SOP">Download SOPs</button>
			</Col>
		</Row>
	</Container>
</div>

<style lang="scss">
	.VideoListing {
		width: 100%;
		display: block;
		padding: 3.125rem 0;

		&-Headline {
			font-family: 'Oswald', 'Open Sans', sans-serif;
			font-size: 1.375rem;
			font-weight: 300;
			margin-bottom: 1.5rem;
			height: 26px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-Image {
			width: 100%;
			display: block;
			cursor: pointer;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
</style>
