<script lang="ts">
	import { FormGroup, Label, Input, FormFeedback } from 'sveltestrap';

	export let id: string;
	export let label: string;
	export let isError: string = '';
</script>

<FormGroup class="custom-control custom-checkbox">
	<Input type="checkbox" class="custom-control-input" {id} invalid={!!isError} on:input {...$$restProps} />
	<Label class="custom-control-label text-secondary" for={id}>{label} <small class="text-danger">*</small></Label>
	<FormFeedback type="invalid">{isError}</FormFeedback>
</FormGroup>
